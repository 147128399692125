import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { FloatingPortal, flip, useDismiss, useFloating, useInteractions } from '@floating-ui/react'

const Dropdown = ({
  title,
  titleElement,
  className,
  chevronClassName,
  header,
  dropdownPad = true,
  stopEventPropagation = false,
  children,
}: {
  title: React.ReactNode
  titleElement?: JSX.Element
  header?: string
  className?: string
  chevronClassName?: string
  dropdownPad?: boolean
  stopEventPropagation?: boolean
  children: React.ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const { x, y, strategy, refs, context } = useFloating({
    placement: 'bottom-start',
    middleware: [flip()],
    open,
    onOpenChange: setOpen,
  })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return (
    <div className="relative">
      <button
        ref={refs.setReference}
        type="button"
        className={classNames('group hover:bg-blue-100 px-6 py-4 flex justify-between', className)}
        onClick={(e) => {
          setOpen(!open)
          if (stopEventPropagation) e.stopPropagation()
        }}
        {...getReferenceProps()}
      >
        {!titleElement && <span className="mr-2">{title}</span>}
        {titleElement && <span>{titleElement}</span>}
        <ChevronDownIcon
          className={classNames('opacity-0 h-5 w-5 group-hover:opacity-100', chevronClassName)}
          aria-hidden="true"
        />
      </button>

      <FloatingPortal>
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            ref={refs.setFloating}
            className={classNames(
              'z-50 origin-top-left absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto',
              { 'p-4': dropdownPad }
            )}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 9999999,
            }}
            {...getFloatingProps()}
          >
            {header && <p className="font-bold text-gray-900 mb-1">{header}</p>}
            {children}
          </div>
        </Transition>
      </FloatingPortal>
    </div>
  )
}

export default Dropdown
