import { useMemo, createContext } from 'react'
import { DateTime } from 'luxon'

import Time from '@/gf/modules/Time'
import FormatFilters from '@/gf/modules/FormatFilters'
import FilterSet from '@/gf/modules/FilterSet'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { FrameQuery, useFrameQuery } from '@/buyers/_gen/gql'
import useSession from '@/buyers/hooks/useSession'
import ConversationM from '@/buyers/modules/Conversation'

export const todaysEventsfilters = [
  [
    'date_time_in_range',
    'inserted_at',
    Time.toPayload(DateTime.now().startOf('day')) as string,
    Time.toPayload(DateTime.now().endOf('day')) as string,
  ],
]

export const FrameContext = createContext<
  | {
      unreadConversations: number
      todos: FrameQuery['allTodos']
      isProPlan: boolean
      todayActivitiesCount: number
    }
  | undefined
>(undefined)

const FrameProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, orgId } = useSession()

  const { data, loading } = useFrameQuery({
    client: useGqlClient(),
    variables: {
      filters: FormatFilters.andFilters(
        FilterSet.toApiFilters(ConversationM.inboxUnreadMessagesConversationFilters(user.id))
      ),
      orgId,
      eventsFilters: todaysEventsfilters,
    },
    pollInterval: ConversationM.CONVERSATION_POLL_INTERVAL,
  })

  const value = useMemo(
    () =>
      data && {
        todos: data.allTodos.filter((td) => td.count > 0),
        todayActivitiesCount: data.countAllEvents,
        unreadConversations: data.unreadConversations,
        isProPlan: !!data.planSubscription?.id,
      },
    [data, loading]
  )

  return <FrameContext.Provider value={value}>{children}</FrameContext.Provider>
}

export default FrameProvider
