import { useChangePasswordMutation, useUpdateProfileMutation } from '@/dealers/_gen/gql'
import ChangeProfileImage from '@/dealers/components/ChangeProfileImage'
import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import PhoneInput from '@/gf/components/PhoneInput'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import GraphQL from '@/gf/modules/GraphQL'
import Phone from '@/gf/modules/Phone'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import Section from './Section'
import SectionSeparator from './SectionSeparator'

const profileValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
})

const passwordValidationSchema = Yup.object().shape({
  current: Yup.string().required('Current Password is required'),
  new: Yup.string().required('New Password is required'),
  confirmation: Yup.string()
    .required('Password Confirmation is required')
    .oneOf([Yup.ref('new'), null], 'Passwords must match'),
})

const Profile = () => {
  const [updateProfile, { loading: profileUpdateInProgress }] = useUpdateProfileMutation()
  const [changePassword, { loading: passwordChangeInProgress }] = useChangePasswordMutation()
  const [_msgs, msgsMgr] = useMsgs()

  const { user } = useSession()
  const profileForm = useForm({
    defaultValues: {
      name: user.name,
      phoneNumber: user.phoneNumber ?? '',
    },
    resolver: yupResolver(profileValidationSchema),
  })

  const passwordForm = useForm({
    defaultValues: {
      current: '',
      new: '',
      confirmation: '',
    },
    resolver: yupResolver(passwordValidationSchema),
  })

  const onSubmitProfile = async (values) => {
    try {
      await updateProfile({ variables: values })
      msgsMgr.add('Profile updated', 'positive')
    } catch (err) {
      msgsMgr.add('Something went wrong', 'negative')
      console.error(err)
    }
  }

  const onSubmitPassword = async (values) => {
    try {
      await changePassword({ variables: values })
      passwordForm.reset()
      msgsMgr.add('Password changed', 'positive')
    } catch (err) {
      const errors = GraphQL.parseErrorList(err)
      if (errors.length > 0) {
        passwordForm.setError('new', { message: errors[0] })
      }
      msgsMgr.add('Something went wrong', 'negative')
      console.error(err)
    }
  }

  return (
    <div className="w-full max-w-screen-md">
      <Box className="px-6 py-6 ">
        <Section title="Personal Information">
          <div className="flex">
            <ChangeProfileImage className="rounded-lg" size="lg" showEditAlways />
          </div>

          <form onSubmit={profileForm.handleSubmit(onSubmitProfile)} className="space-y-4">
            <TextField
              label="Name"
              {...profileForm.register('name')}
              errors={profileForm.formState.errors.name?.message}
            />
            <Controller
              name="phoneNumber"
              control={profileForm.control}
              render={({ field }) => (
                <PhoneInput
                  label="Phone"
                  value={field.value ? Phone.format(field.value) : ''}
                  onChange={(value) => field.onChange(value)}
                  errors={profileForm.formState.errors.phoneNumber?.message}
                />
              )}
            />

            <div className="pt-2">
              <Action.P type="submit" performing={profileUpdateInProgress} className="w-40">
                Save
              </Action.P>
            </div>
          </form>
        </Section>

        <SectionSeparator />

        <Section
          title="Change Password"
          subtitle="Update your password associated with this account"
        >
          <form onSubmit={passwordForm.handleSubmit(onSubmitPassword)} className="space-y-4">
            <TextField
              label="Current Password"
              type="password"
              autoComplete="off"
              {...passwordForm.register('current')}
              errors={passwordForm.formState.errors.current?.message}
            />

            <TextField
              label="New Password"
              type="password"
              autoComplete="off"
              {...passwordForm.register('new')}
              errors={passwordForm.formState.errors.new?.message}
            />

            <TextField
              label="Confirm Password"
              type="password"
              autoComplete="off"
              {...passwordForm.register('confirmation')}
              errors={passwordForm.formState.errors.confirmation?.message}
            />

            <div className="pt-2">
              <Action.P type="submit" performing={passwordChangeInProgress} className="w-40">
                Save
              </Action.P>
            </div>
          </form>
        </Section>
      </Box>
    </div>
  )
}

export default Profile
