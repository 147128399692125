import {
  RequestForQuoteStep,
  RequestsQuery,
  SortByInput,
  SortOrder,
  useRequestCountsQuery,
  useRequestsQuery,
} from '@/dealers/_gen/gql'
import Frame from '@/dealers/components/Frame'
import useSession from '@/dealers/hooks/useSession'
import RequestForQuoteM from '@/dealers/modules/RequestForQuote'
import Urls from '@/dealers/modules/Urls'
import A from '@/gf/components/A'
import Box from '@/gf/components/Box'
import Dropdown from '@/gf/components/Dropdown'
import FilterAction from '@/gf/components/FilterAction'
import FilterProgressNav from '@/gf/components/FilterProgressNav'
import Ghost from '@/gf/components/Ghost'
import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/Link'
import MachineDownIcon from '@/gf/components/MachineDownIcon'
import MultiButton, { MultiButtonAction, MultiButtonLink } from '@/gf/components/MultiButton'
import NeededByPill from '@/gf/components/NeededByPill'
import Page from '@/gf/components/Page'
import Pagination, { PaginationGhost } from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import SortByHeaderButton from '@/gf/components/SortByHeaderButton'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import useWindowWidth from '@/gf/hooks/useWindowWidth'
import { trackSearch } from '@/gf/modules/Analytics'
import FormatFilters from '@/gf/modules/FormatFilters'
import Id from '@/gf/modules/Id'
import Money from '@/gf/modules/Money'
import { Transition } from '@headlessui/react'
import {
  ChatAlt2Icon,
  ClipboardListIcon,
  CubeIcon,
  PencilAltIcon,
  TruckIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline'
import { UserIcon as UserIconSolid } from '@heroicons/react/solid'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import nth from 'lodash/nth'
import { DateTime } from 'luxon'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import {
  BooleanParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import RfqStepBadgeNext from '../components/RfqStepBadgeNext'
import { ModalTypes } from './Request/useModal'
import NotifyMe from './Requests/NotifyMe'
import PartsCell from './Requests/PartsCell'

export type RequestForQuote = RequestsQuery['paginatedRequestForQuotes']['entries'][number]
type AssignedFilter = 'unassigned' | 'my requests'
type AvailabilityFilter = 'in stock' | 'special order' | 'needs special order'

const messageAction = (rfq: RequestForQuote): MultiButtonLink => ({
  display: 'Message',
  description:
    'Send your Customer a message with an SMS and email notification (depending on their preferences).',
  icon: ChatAlt2Icon,
  to: Urls.requestForQuoteMessages(rfq.id),
})

const assignAction = (rfq: RequestForQuote): MultiButtonLink => ({
  display: 'Assign to Rep',
  description: 'Assign this Request to someone on your team.',
  icon: UserIcon,
  to: `${Urls.requestForQuote(rfq.id)}?assignOwner=1`,
})

const inboundActions = (rfq: RequestForQuote): (MultiButtonAction | MultiButtonLink)[] => [
  {
    display: 'Create Quote',
    description: 'Send your Customer a Quote for their Request.',
    icon: ClipboardListIcon,
    to: Urls.requestForQuote(rfq.id),
  },
  assignAction(rfq),
  messageAction(rfq),
  {
    display: <>Can&apos;t Participate</>,
    description: "Let your customer know why you aren't able to participate in this request.",
    icon: XIcon,
    to: `/rfqs/${rfq.id}/cant-participate`,
  },
]

const quotedActions = (rfq: RequestForQuote): (MultiButtonAction | MultiButtonLink)[] => [
  messageAction(rfq),
  {
    display: 'Edit Quote',
    description: 'Send your Customer a revised Quote for their Request.',
    icon: PencilAltIcon,
    to: `${Urls.requestForQuote(rfq.id)}?edit=1`,
  },
]

const poReceivedActions = (rfq: RequestForQuote): (MultiButtonAction | MultiButtonLink)[] => [
  ...(rfq.quote?.pickup
    ? [
        {
          display: 'Ready',
          description:
            'Let your customer know the order is ready when you have prepared the items for them.',
          icon: CubeIcon,
          to: Urls.requestForQuoteModal(rfq.id, ModalTypes.READY_FOR_PICKUP),
        },
      ]
    : []),
  ...(rfq.quote && !rfq.quote.pickup
    ? [
        {
          display: 'Create Shipment',
          description: 'Send the shipment information to your customer when you ship any items.',
          icon: TruckIcon,
          to: Urls.requestForQuoteModal(rfq.id, ModalTypes.CREATE_SHIPPING),
        },
      ]
    : []),
  messageAction(rfq),
]

const fulfillingActions = (rfq: RequestForQuote): MultiButtonLink[] => [messageAction(rfq)]

const closedActions = (rfq: RequestForQuote): MultiButtonLink[] => [messageAction(rfq)]

const stepActions = (rfq: RequestForQuote) =>
  rfq.step === RequestForQuoteStep.Inbound
    ? inboundActions(rfq)
    : rfq.step === RequestForQuoteStep.Quoted
      ? quotedActions(rfq)
      : rfq.step === RequestForQuoteStep.PoReceived
        ? poReceivedActions(rfq)
        : rfq.step === RequestForQuoteStep.Fulfilling
          ? fulfillingActions(rfq)
          : closedActions(rfq)

const TrGhost = ({
  showActions = false,
  showFulfillment = false,
}: {
  showActions?: boolean
  showFulfillment?: boolean
}) => (
  <Tr>
    <Td className="w-0">
      <Ghost className="inline-block h-5 w-32 min-w-32 bg-gray-300" />
    </Td>
    <Td>
      <Ghost className="inline-block h-5 w-32 min-w-32 bg-gray-300" />
    </Td>
    <Td>
      <Ghost className="inline-block h-5 w-32 min-w-32 bg-gray-300" />
    </Td>
    <Td>
      <Ghost className="inline-block h-5 w-32 min-w-32 bg-gray-300" />
    </Td>
    {showFulfillment && (
      <Td className="w-0">
        <Ghost className="inline-block h-5 w-32 min-w-32 bg-gray-300" />
      </Td>
    )}
    <Td className="w-0">
      <Ghost className="inline-block h-5 w-32 min-w-32 bg-gray-300" />
    </Td>
    {showActions && (
      <Td className="w-0 sticky right-0 z-10 px-3 py-1.5 bg-white">
        <Ghost className="inline-block w-44 h-8 min-w-44 bg-gray-300" />
      </Td>
    )}
  </Tr>
)

const UserBadge = ({ children }: { children: ReactNode }) => (
  <div className="flex gap-1 items-center text-slate-400">
    <UserIconSolid className="w-4 h-4 shrink-0" />
    <span className="mt-0.5">{children}</span>
  </div>
)

const now = DateTime.now()

const formatDateTime = (dateTime: DateTime) => {
  let format = DateTime.DATETIME_SHORT
  format = dateTime.year === now.year ? { ...format, year: undefined } : format
  return dateTime.toLocaleString(format)
}

const useQueryParamsOpts = {
  selected: withDefault(NumberParam, 1),
  assigned: StringParam,
  availability: StringParam,
  'has-problems': withDefault(BooleanParam, false),
  sortBy: withDefault<SortByInput, SortByInput>(JsonParam, {
    field: 'needed_by',
    order: SortOrder.Desc,
  }),
}

const useRequestsQueryParams = () => {
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)

  return [
    query as Omit<typeof query, 'availability' | 'assigned'> & {
      availability: AvailabilityFilter | null
      assigned: AssignedFilter | null
    },
    updateQuery,
  ] as const
}

const Requests = () => {
  const {
    user: { id: userId },
    store,
    featureFlags,
  } = useSession()

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [query, updateQuery] = useRequestsQueryParams()

  const countsData = useRequestCountsQuery({
    variables: {
      filtersList: RequestForQuoteM.allSteps
        .filter((s) => s.id !== 'fulfilled')
        .map((s) => RequestForQuoteM.stepApiFilters(s)),
    },
  }).data

  const steps = RequestForQuoteM.allSteps.map((step, index) => ({
    ...step,
    count: countsData && nth(countsData.requestCounts, index),
  }))

  const step = steps[query.selected]
  const stepApiFilters = RequestForQuoteM.stepApiFilters(step)

  const apiFilters = FormatFilters.andFilters([
    ...stepApiFilters,
    ...(query.assigned === 'my requests' ? [['is_assigned_to', userId]] : []),
    ...(query.assigned === 'unassigned' ? [['is_unassigned']] : []),
    ...(query.availability === 'needs special order' ? [['needs_backordered']] : []),
    ...(query.availability === 'special order' ? [['is_backordered']] : []),
    ...(query.availability === 'in stock' ? [['in_stock']] : []),
    ...(query['has-problems'] ? [['has_problems']] : []),
  ])

  const requestForQuotes = useRequestsQuery({
    variables: {
      page,
      filters: apiFilters,
      sortBy: query.sortBy,
      search,
      pageSize: null,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (resultData) => {
      if (search) {
        trackSearch(search, 'requests_page', {
          resultsFound: resultData.paginatedRequestForQuotes.pagination.totalResults,
        })
      }
    },
  }).data?.paginatedRequestForQuotes

  const [showActionDivider, setShowActionDivider] = useState(false)
  const tableRef = useRef<HTMLDivElement>(null)
  const windowWidth = useWindowWidth(300)

  const handleShowActionDivider = useCallback(
    (scrollLeft: number, scrollWidth: number, clientWidth: number) => {
      const threshold = 5
      const isScrollAtEnd = scrollLeft + threshold >= scrollWidth - clientWidth
      if (isScrollAtEnd && showActionDivider) setShowActionDivider(false)
      else if (!isScrollAtEnd && !showActionDivider) setShowActionDivider(true)
    },
    [showActionDivider, setShowActionDivider]
  )

  useEffect(() => {
    if (tableRef.current && requestForQuotes) {
      handleShowActionDivider(
        tableRef.current.scrollLeft,
        tableRef.current.scrollWidth,
        tableRef.current.clientWidth
      )
    }
  }, [tableRef, handleShowActionDivider, requestForQuotes])

  useEffect(() => {
    if (tableRef.current) {
      handleShowActionDivider(
        tableRef.current.scrollLeft,
        tableRef.current.scrollWidth,
        tableRef.current.clientWidth
      )
    }
  }, [windowWidth])

  const onSearchChanged = (searchTerm: string) => {
    setPage(1)
    setSearch(searchTerm)
  }

  const onSearchChangedDebounced = useRef(debounce(onSearchChanged, 300)).current
  const showNeededBy = step.id === 'inbound'
  const showCreated = ['all', 'inbound'].includes(step.id)
  const showQuoteCreated = step.id !== 'inbound'
  const showOrderTotal = step.id !== 'inbound'
  const showPONumber = ['po_received', 'fulfilling', 'fulfilled'].includes(step.id)
  const showStatus = step.id === 'all'

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [{ name: 'Requests', href: Urls.requestForQuotes() }],
      }}
    >
      <Page
        title="Requests"
        actionsNext={[
          <Link.S to={Urls.createQuote()} key="create-quote">
            Create Quote
          </Link.S>,
        ]}
      >
        <Layout>
          <Layout.Section className="pb-8" type="full">
            <FilterProgressNav
              steps={steps}
              selectedIndex={query.selected}
              setSelectedIndex={(selectedIndex) => updateQuery({ selected: selectedIndex })}
              isFirstStepAnIsland
              textColorClassName="text-orange-500"
              bgColorClassName="bg-orange-500"
            />

            <Box className="!rounded-sm">
              <div className="pt-3 pb-1 px-4 bg-gray-50 space-y-4">
                <SearchInput
                  value={search}
                  onChange={onSearchChangedDebounced}
                  placeholder="type to search for part number, part name, serial number, model, customer name, owner name, etc."
                />

                <div className="flex flex-wrap gap-2 items-center">
                  <div className="text-sm text-slate-500 whitespace-nowrap">Filter by:</div>

                  <FilterAction
                    selected={query.assigned === 'my requests'}
                    onSelect={() =>
                      updateQuery({
                        assigned: query.assigned === 'my requests' ? undefined : 'my requests',
                      })
                    }
                  >
                    My Requests
                  </FilterAction>

                  <FilterAction
                    selected={query.assigned === 'unassigned'}
                    onSelect={() =>
                      updateQuery({
                        assigned: query.assigned === 'unassigned' ? undefined : 'unassigned',
                      })
                    }
                  >
                    Unassigned
                  </FilterAction>

                  {featureFlags.specialOrderNumber && (
                    <FilterAction
                      selected={query.availability === 'needs special order'}
                      onSelect={() =>
                        updateQuery({
                          availability:
                            query.availability === 'needs special order'
                              ? undefined
                              : 'needs special order',
                        })
                      }
                    >
                      Needs Special Order
                    </FilterAction>
                  )}

                  <FilterAction
                    selected={query.availability === 'special order'}
                    onSelect={() =>
                      updateQuery({
                        availability:
                          query.availability === 'special order' ? undefined : 'special order',
                      })
                    }
                  >
                    Special Order
                  </FilterAction>

                  <FilterAction
                    selected={query.availability === 'in stock'}
                    onSelect={() =>
                      updateQuery({
                        availability: query.availability === 'in stock' ? undefined : 'in stock',
                      })
                    }
                  >
                    In Stock
                  </FilterAction>

                  <FilterAction
                    selected={query['has-problems']}
                    onSelect={() =>
                      updateQuery({ 'has-problems': query['has-problems'] ? undefined : true })
                    }
                  >
                    Has Problems
                  </FilterAction>
                </div>
              </div>

              <Table
                ref={tableRef}
                onScroll={(e) =>
                  handleShowActionDivider(
                    e.currentTarget.scrollLeft,
                    e.currentTarget.scrollWidth,
                    e.currentTarget.clientWidth
                  )
                }
              >
                <Thead>
                  <Tr>
                    {showNeededBy && (
                      <Th>
                        <SortByHeaderButton
                          field="needed_by"
                          display="Needed By"
                          sortBy={query.sortBy}
                          setSortBy={(sortBy) => updateQuery({ sortBy })}
                        />
                      </Th>
                    )}

                    {showCreated && (
                      <Th>
                        <SortByHeaderButton
                          field="inserted_at"
                          display="Created"
                          sortBy={query.sortBy}
                          setSortBy={(sortBy) => updateQuery({ sortBy })}
                        />
                      </Th>
                    )}

                    {showQuoteCreated && <Th>Quote Created</Th>}
                    {showStatus && <Th>Status</Th>}

                    <Th>Request</Th>
                    <Th>Parts</Th>

                    {showOrderTotal && (
                      <Th>
                        <SortByHeaderButton
                          field="order_total"
                          display="Order Total"
                          sortBy={query.sortBy}
                          setSortBy={(sortBy) => updateQuery({ sortBy })}
                        />
                      </Th>
                    )}

                    {showPONumber && <Th>PO Number</Th>}

                    <Th>Machine</Th>
                    <Th>Customer</Th>
                    <Th>Fulfillment</Th>
                    <Th className="hidden sm:table-cell w-0 sticky right-0 z-10 bg-gray-50 bg-opacity-80" />
                    <Th className="table-cell sm:hidden" />
                  </Tr>
                </Thead>
                <Tbody>
                  {requestForQuotes?.entries.map((rfq, index) => (
                    <Tr key={rfq.id}>
                      {showNeededBy && (
                        <Td>
                          <NeededByPill rfq={rfq} />
                        </Td>
                      )}

                      {showCreated && <Td>{formatDateTime(rfq.insertedAt)}</Td>}

                      {showQuoteCreated && (
                        <Td>
                          {rfq.quote && (
                            <div className="flex flex-col gap-1">
                              {formatDateTime(rfq.quote.creation.at)}
                              <UserBadge>{rfq.quote.creation.creator.name}</UserBadge>
                            </div>
                          )}
                        </Td>
                      )}

                      {showStatus && (
                        <Td>
                          <RfqStepBadgeNext step={rfq.step} />
                        </Td>
                      )}

                      <Td>
                        <div className="flex flex-col gap-1">
                          <A.T href={Urls.requestForQuote(rfq.id)} className="text-base">
                            Request{' '}
                            {store.customizedRequestLabel && rfq.quote?.quoteNumber
                              ? rfq.quote.quoteNumber
                              : Id.shorten(rfq.id)}
                          </A.T>

                          {rfq.assignedUser && <UserBadge>{rfq.assignedUser.name}</UserBadge>}
                        </div>
                      </Td>

                      <PartsCell rfq={rfq}>
                        {featureFlags.specialOrderNumber && rfq.quote?.specialOrderNumber && (
                          <div className="pl-6 -mt-4 flex items-center text-slate-400">
                            <span className="mt-1">
                              Special Order: {rfq.quote.specialOrderNumber}
                            </span>
                          </div>
                        )}
                      </PartsCell>

                      {showOrderTotal && (
                        <Td className="text-right">
                          <div className="flex flex-col gap-1">
                            {rfq.quote && Money.format(rfq.quote.totals.total)}

                            {rfq.quote?.quoteNumber && (
                              <span className="text-slate-400">
                                Quote num: {rfq.quote?.quoteNumber}
                              </span>
                            )}
                          </div>
                        </Td>
                      )}

                      {showPONumber && (
                        <Td>
                          <div className="flex flex-col gap-1">
                            {rfq.quote?.purchaseOrder ||
                              (rfq.quote && <>Order ID: {Id.shorten(rfq.quote.id)}</>)}

                            {rfq.timeline.approvedAt && (
                              <span className="text-slate-400">
                                Approved: {formatDateTime(rfq.timeline.approvedAt)}
                              </span>
                            )}
                          </div>
                        </Td>
                      )}

                      <Td className="p-0">
                        <div className="text-left divide-y-1 divide-dotted">
                          {rfq.machines.map((machine) => (
                            <Dropdown
                              key={machine.id}
                              className="text-left"
                              chevronClassName={machine.machineDown ? 'mt-1' : undefined}
                              title={machine.details.name}
                              titleElement={
                                <div className="flex flex-row items-center gap-x-1">
                                  {machine.machineDown && <MachineDownIcon />}
                                  {machine.details.machine.make} {machine.details.machine.model}
                                </div>
                              }
                              stopEventPropagation
                            >
                              <div className="flex flex-row gap-x-4 text-sm whitespace-nowrap">
                                <div className="flex flex-col">
                                  <span>Unit #</span>
                                  <span>Serial #</span>
                                  <span>Make</span>
                                  <span>Model</span>
                                  <span>Year</span>
                                </div>

                                <div className="flex flex-col">
                                  <span>{machine.details.name}</span>
                                  <span>{machine.details.serialNumber}</span>
                                  <span>{machine.details.machine.make}</span>
                                  <span>{machine.details.machine.model}</span>
                                  <span>{machine.details.machine.year}</span>
                                </div>
                              </div>
                            </Dropdown>
                          ))}
                        </div>
                      </Td>

                      <Td>
                        {RequestForQuoteM.canDisplayCustomerContactInfo({
                          user: { isCustomerContact: rfq.user.isCustomerContact },
                          step: rfq.step,
                          storeOrder: rfq.quote ? { state: rfq.quote.state } : null,
                        }) ? (
                          <div className="flex flex-col gap-1">
                            {rfq.user.organization.name}
                            <div className="text-slate-400">{rfq.user.name}</div>
                          </div>
                        ) : (
                          <p className="text-slate-400">New Customer</p>
                        )}
                      </Td>

                      <Td>
                        {rfq.quote?.pickup !== undefined && (
                          <div className="flex flex-col gap-1">
                            <div className="flex flex-row gap-x-1">
                              {rfq.quote.pickup ? (
                                <>
                                  <CubeIcon className="w-5 h-5" />
                                  Will Call
                                </>
                              ) : (
                                <>
                                  <TruckIcon className="-mx-0.5 w-5 h-5" />
                                  Delivery
                                </>
                              )}
                            </div>

                            {(step.id === 'fulfilling' || step.id === 'fulfilled') && (
                              <div className="text-slate-400">
                                {(() => {
                                  const fulfilledAt =
                                    rfq.quote.timeline.outForDeliveryAt ||
                                    rfq.quote.timeline.readyForPickupAt ||
                                    rfq.quote.timeline.shippedAt

                                  return (
                                    fulfilledAt && <>Fulfilled: {formatDateTime(fulfilledAt)}</>
                                  )
                                })()}
                              </div>
                            )}
                          </div>
                        )}
                      </Td>

                      <Td className="hidden sm:table-cell w-0 sticky right-0 z-10 p-0">
                        <div className="absolute inset-0 z-30 bg-white bg-opacity-90" />
                        <Transition
                          show={!!showActionDivider}
                          enter="transition-opacity duration-75"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div
                            className={classNames(
                              'w-4 absolute -left-4 z-20 opacity-5 bg-gradient-to-l from-black',
                              index === 0 || index === requestForQuotes.entries.length - 1
                                ? 'inset-y-0'
                                : '-inset-y-px'
                            )}
                          />
                        </Transition>
                        <div className="px-3 py-1.5 relative z-40">
                          <MultiButton actions={stepActions(rfq)} align="right" />
                        </div>
                      </Td>

                      <Td className="table-cell sm:hidden p-0">
                        <div className="px-3 py-1.5 relative z-40">
                          <MultiButton actions={stepActions(rfq)} align="right" />
                        </div>
                      </Td>
                    </Tr>
                  ))}

                  {!requestForQuotes && (
                    <>
                      <TrGhost showActions showFulfillment />
                      <TrGhost showActions showFulfillment />
                      <TrGhost showActions showFulfillment />
                      <TrGhost showActions showFulfillment />
                      <TrGhost showActions showFulfillment />
                      <TrGhost showActions showFulfillment />
                    </>
                  )}

                  {requestForQuotes?.entries.length === 0 && (
                    <Tr>
                      <Td colSpan={999}>
                        <div className="space-y-4">
                          <div>
                            No <span className="font-medium text-slate-900">{step.name}</span>{' '}
                            Requests found
                            {!!search && (
                              <>
                                {' '}
                                for <span className="font-medium text-slate-900">{search}</span>
                              </>
                            )}
                          </div>

                          {featureFlags.notifyMe && <NotifyMe stepId={step.id} />}
                        </div>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>

            {!requestForQuotes ? (
              <PaginationGhost />
            ) : (
              <Pagination
                page={page}
                updatePage={setPage}
                pagination={requestForQuotes.pagination}
              />
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}

export default Requests
