import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import ReactSelect, {
  components,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
} from 'react-select'
import type { GroupBase, Props } from 'react-select'
import ChevronPicker from '../svgs/ChevronPicker'

function DropdownIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronPicker className="h-6" />
    </components.DropdownIndicator>
  )
}

function ClearIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ClearIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.ClearIndicator {...props}>
      <XIcon className="h-4" />
    </components.ClearIndicator>
  )
}

function MultiValueRemove<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueRemoveProps<Option, IsMulti, Group>) {
  return (
    <components.MultiValueRemove {...props}>
      <XIcon className="h-4 self" />
    </components.MultiValueRemove>
  )
}

const containerStyles = 'self-stretch'
const controlStyles = {
  base: 'border rounded-md bg-white hover:cursor-pointer h-full',
  focus: 'border-blue-600 ring-1 ring-blue-500',
  nonFocus: 'border-gray-300 hover:border-gray-400',
}
const placeholderStyles = 'text-gray-400 pl-1 py-0.5'
// const selectInputStyles = 'pl-1 py-0.5'
const selectInputStyles = ''
const valueContainerStyles = (isMulti) => classNames('px-3  gap-1', isMulti ? 'py-1.5' : 'py-2')
const singleValueStyles = 'ml-1 leading-[normal]'
const multiValueStyles =
  'bg-gray-50 text-gray-600 border border-gray-700 border-opacity-10 rounded items-center pl-2 pr-1 gap-1.5'
const multiValueLabelStyles = 'leading-6 text-xs'
const multiValueRemoveStyles = 'text-gray-600'
const indicatorsContainerStyles = 'p-1'
const clearIndicatorStyles = 'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800'
const indicatorSeparatorStyles = ''
const dropdownIndicatorStyles = 'p-0.5 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black'
const menuStyles = 'mt-2 border border-gray-300 bg-white rounded-md shadow-md overflow-hidden'
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm'
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2',
  focus: 'bg-gray-50 active:bg-gray-50',
  selected: 'text-blue-600',
}
const noOptionsMessageStyles = 'text-gray-500 px-3 py-2 bg-gray-50'
const loadingMessageStyles = 'px-3 py-2 text-slate-600 text-left text-sm'

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  components: customComponents,
  classNames: customClassNames,
  ...props
}: Props<Option, IsMulti, Group>) => (
  <ReactSelect
    closeMenuOnSelect={!props.isMulti}
    hideSelectedOptions={false}
    unstyled
    styles={{
      input: (base) => ({
        ...base,
        'input:focus': {
          boxShadow: 'none',
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (base) => ({
        ...base,
        whiteSpace: 'normal',
        overflow: 'visible',
      }),
      control: (base) => ({
        ...base,
        transition: 'none',
      }),
      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    }}
    components={{
      DropdownIndicator: DropdownIndicator<Option, IsMulti, Group>,
      ClearIndicator: ClearIndicator<Option, IsMulti, Group>,
      MultiValueRemove: MultiValueRemove<Option, IsMulti, Group>,
      ...(customComponents ?? {}),
    }}
    classNames={{
      control: ({ isFocused, isDisabled }) =>
        classNames(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlStyles.base,
          isDisabled && 'bg-gray-50'
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: ({ isMulti }) => valueContainerStyles(isMulti),
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isFocused, isSelected }) =>
        classNames(
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
      loadingMessage: () => loadingMessageStyles,
      container: () => containerStyles,
      ...(customClassNames ?? {}),
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
    {...props}
  />
)

export default Select
