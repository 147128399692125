import Action from '@/gf/components/Action'
import ProgressBar from '@/gf/components/next/ProgressBar'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import { Maybe } from '@/types'
import { CheckIcon } from '@heroicons/react/outline'
import { CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'
import ProFeatureLabel from './ProFeatureLabel'
import RestartIcon from './RestartIcon'

const Button = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode
    disabled?: boolean
    primary?: boolean
    round?: boolean
    onClick: () => void
  }
>(({ children, disabled = false, primary = false, round = false, onClick }, ref) => (
  <Action.S
    disabled={disabled}
    className={classNames(
      'text-center font-medium h-9 !flex !items-center !justify-center',
      !round && 'flex-grow',
      primary && !disabled && '!bg-blue-600 !border-blue-600 text-white',
      disabled && '!bg-transparent !text-gray-300 !border-0 !shadow-none !opacity-100',
      round && '!rounded-full !p-0 w-9 flex-shrink-0'
    )}
    onClick={onClick}
    ref={ref}
  >
    {children}
  </Action.S>
))

const TaskCard = ({
  disabled = false,
  locked = false,
  progress = null,
  completed = false,
  title,
  text,
  index,
  onRestart,
  onContinue,
  onBegin,
  onComplete,
  onLearnMore,
  className,
}: {
  disabled?: boolean
  locked?: boolean
  progress?: Maybe<number>
  completed?: boolean
  title: string
  text: string
  index: number
  onRestart?: () => void
  onContinue: () => void
  onBegin: () => void
  onComplete?: () => void
  onLearnMore: () => void
  className?: string
}) => {
  const inProgress = progress !== null ? progress > 0 && !completed : true
  const notStarted = progress === 0

  return (
    <section
      className={classNames(
        'border border-gray-200 rounded-xl p-6 w-[16.18rem] h-[20.5rem] flex-shrink-0 flex flex-col',
        disabled ? 'bg-gray-50' : 'bg-white shadow-base',
        className
      )}
    >
      {progress !== null && (
        <>
          {locked ? (
            <span className="block h-1" />
          ) : (
            <ProgressBar progressPercent={progress} bgColorClassName="bg-gray-100" height="1" />
          )}
        </>
      )}

      <hgroup className="space-y-4 mt-2">
        {locked ? (
          <ProFeatureLabel />
        ) : (
          <p className="font-medium text-sm text-blue-600 flex items-center gap-x-1">
            {completed && <CheckCircleIcon className="inline-block h-5 w-5" />}
            Step {index} {completed && 'Complete'}
          </p>
        )}

        <h3 className="text-xl font-medium">{title}</h3>
      </hgroup>

      <p className="text-base mt-2 flex-grow">{text}</p>

      <div className="flex gap-x-2 justify-end">
        {locked ? (
          <Button onClick={onLearnMore} disabled={disabled}>
            Learn More
          </Button>
        ) : (
          <>
            {notStarted && (
              <Button onClick={onBegin} disabled={disabled} primary>
                Begin
              </Button>
            )}
            {completed && onRestart && !disabled && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button onClick={onRestart} round disabled={disabled}>
                    <RestartIcon />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-gray-900 bg-opacity-90 text-white text-sm px-1.5 py-1 rounded-md">
                  <p>Mark Task In Progress</p>
                </TooltipContent>
              </Tooltip>
            )}
            {inProgress && (
              <>
                <Button onClick={onContinue} disabled={disabled}>
                  Continue
                </Button>
                {onComplete && !disabled && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button onClick={onComplete} round disabled={disabled}>
                        <CheckIcon className="h-7 w-7 text-gray-700" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-900 bg-opacity-90 text-white text-sm px-1.5 py-1 rounded-md">
                      <p>Mark Task Complete</p>
                    </TooltipContent>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
      </div>
    </section>
  )
}

const Static = ({
  title,
  text,
  onActionClick,
  className,
}: {
  title: string
  text: string
  onActionClick?: () => void
  className?: string
}) => (
  <section
    className={classNames(
      'border border-gray-200 rounded-xl p-6 w-[16.18rem] h-[18.5rem] flex-shrink-0 flex flex-col',
      'bg-white shadow-base',
      className
    )}
  >
    <h3 className="text-xl font-medium mt-2">{title}</h3>

    <p className="text-base mt-2 flex-grow">{text}</p>

    <Action.P color="blue" onClick={onActionClick} className="block">
      Continue
    </Action.P>
  </section>
)

TaskCard.Static = Static

export default TaskCard
