import classNames from 'classnames'

const ProgressBar = ({
  steps,
  currentStep,
  currentStepProgress,
}: {
  steps: string[]
  currentStep: number
  currentStepProgress: number
}) => (
  <div className="flex gap-x-4 w-full">
    {steps.map((step, i) => (
      <div style={{ width: `${100 / steps.length}%` }} key={step}>
        <span className="block w-full h-1 bg-gray-200 relative">
          <span
            className="block h-full bg-blue-600 transition-all duration-300"
            style={{
              width: i > currentStep ? '0%' : i < currentStep ? '100%' : `${currentStepProgress}%`,
            }}
          />
        </span>
        <span
          className={classNames(
            'block text-sm mt-4',
            i === currentStep ? 'text-blue-600 leading-5' : 'text-gray-500'
          )}
        >
          Step {i + 1}
        </span>
        <span className="block font-medium text-gray-900 leading-6">{step}</span>
      </div>
    ))}
  </div>
)

export default ProgressBar
