import { FloatingPortal, flip, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import { Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import nth from 'lodash/nth'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Action from './MultiButton/ActionWrapper'
import Content, { ContentWrapper, FirstAction } from './MultiButton/Content'
import MultiButtonLink from './MultiButton/LinkWrapper'
import MultiButtonModal from './MultiButton/ModalWrapper'

type HeroIcon = (props: React.ComponentProps<'svg'>) => JSX.Element

export type MultiButton = MultiButtonAction | MultiButtonLink | MultiButtonModal

export type MultiButtonAction = {
  display: React.ReactNode
  icon?: HeroIcon
  iconClassName?: string
  description?: string
  onClick: () => void
  key?: React.Key | null
}

export type MultiButtonLink = {
  display: React.ReactNode
  icon?: HeroIcon
  iconClassName?: string
  description?: string
  to: Parameters<typeof Link>[0]['to']
  target?: Parameters<typeof Link>[0]['target']
  replace?: Parameters<typeof Link>[0]['replace']
  key?: React.Key | null
}

export type MultiButtonModal = {
  display: React.ReactNode
  icon?: HeroIcon
  iconClassName?: string
  description?: string
  modal: ({ open, onClose }: { open: boolean; onClose: () => void }) => JSX.Element
  key?: React.Key | null
}

const Button = ({ item, firstAction }: { item: MultiButton; firstAction?: FirstAction }) => (
  <ContentWrapper firstAction={firstAction}>
    {'onClick' in item ? (
      <Action
        key={item.key}
        action={item}
        setMultiOpen={() => {
          // NOP
        }}
      >
        <Content item={item} firstAction={firstAction} />
      </Action>
    ) : 'to' in item ? (
      <MultiButtonLink
        key={item.key}
        link={item}
        setMultiOpen={() => {
          // NOP
        }}
      >
        <Content item={item} firstAction={firstAction} />
      </MultiButtonLink>
    ) : (
      <MultiButtonModal
        key={item.key}
        multiButton={item}
        setMultiOpen={() => {
          // NOP
        }}
      >
        <Content item={item} firstAction={firstAction} />
      </MultiButtonModal>
    )}
  </ContentWrapper>
)

const Buttons = ({ items }: { items: MultiButton[] }) => (
  <>
    {items.map((item) => (
      <Button key={item.key} item={item} />
    ))}
  </>
)

export const MultiButtons = ({
  items,
  className,
}: {
  items: MultiButton[]
  className?: string
}) => (
  <div
    className={classNames(
      'flex flex-col justify-start items-stretch rounded-md bg-gray-200 shadow-lg border border-gray-200 focus:outline-none divide-y divide-gray-100',
      className
    )}
  >
    <Buttons items={items} />
  </div>
)

const MultiButton = ({
  actions: items,
  align = 'right',
}: {
  actions: MultiButton[]
  align?: 'left' | 'right'
}) => {
  const [multiOpen, setMultiOpen] = useState(false)

  const { x, y, strategy, refs, context } = useFloating({
    placement: align === 'left' ? 'bottom-start' : 'bottom-end',
    middleware: [flip()],
    open: multiOpen,
    onOpenChange: setMultiOpen,
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])
  const firstItem = nth(items, 0)

  return (
    <div className="relative inline-block font-normal rounded-md shadow-sm disabled:opacity-50">
      <div ref={refs.setReference} className="flex flex-row" {...getReferenceProps()}>
        {firstItem && (
          <Button item={firstItem} firstAction={{ hasMultipleActions: items.length > 1 }} />
        )}

        {items.length > 1 && (
          <button
            className={classNames(
              'px-3 py-2 inline-block border border-gray-300 justify-center rounded-r-md bg-white hover:bg-gray-50',
              multiOpen && 'bg-gray-50'
            )}
            onClick={() => setMultiOpen((prev) => !prev)}
            type="button"
          >
            {/* Options */}
            <ChevronDownIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </button>
        )}
      </div>

      <FloatingPortal>
        <Transition
          as={Fragment}
          show={multiOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            ref={refs.setFloating}
            className={classNames(
              'absolute z-[3000] mt-1 mb-1 w-64 focus:outline-none',
              align === 'left' ? 'left-0 origin-top-left' : 'right-0 origin-top-right'
            )}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            <MultiButtons items={items} />
          </div>
        </Transition>
      </FloatingPortal>
    </div>
  )
}

export default MultiButton
