import { Point } from '../../types'

export const US_CENTER = {
  lat: 39.76237637866829,
  lng: -102.66840242358424,
} as Point

export const ORANGE_PIN = {
  path: 'M14.3333 10.8908C12.6802 12.5437 11.3689 14.506 10.4743 16.6657C9.57961 18.8254 9.11914 21.1402 9.11914 23.4779C9.11914 25.8156 9.57961 28.1304 10.4743 30.2901C11.3689 32.4498 12.6802 34.4121 14.3332 36.065C14.3333 36.065 14.3333 36.0651 14.3333 36.0651L26.2132 47.945L26.9204 48.6521L27.6275 47.945L39.5075 36.065C42.8458 32.7267 44.7212 28.199 44.7212 23.4779C44.7212 18.7568 42.8458 14.2291 39.5075 10.8908C36.1692 7.55251 31.6414 5.67706 26.9204 5.67706C22.1993 5.67706 17.6716 7.55247 14.3333 10.8908ZM14.3333 10.8908L15.0404 11.5979L14.3333 10.8908ZM29.6074 26.1649C28.8947 26.8776 27.9282 27.2779 26.9204 27.2779C25.9125 27.2779 24.946 26.8776 24.2333 26.1649C23.5207 25.4523 23.1204 24.4857 23.1204 23.4779C23.1204 22.4701 23.5207 21.5036 24.2333 20.7909C24.946 20.0783 25.9125 19.6779 26.9204 19.6779C27.9282 19.6779 28.8947 20.0783 29.6074 20.7909C30.32 21.5036 30.7204 22.4701 30.7204 23.4779C30.7204 24.4857 30.32 25.4523 29.6074 26.1649Z',
  fillColor: '#FF6701',
  fillOpacity: 1,
  scale: 0.7,
  strokeColor: '#ffffff',
  strokeWeight: 1.5,
  anchor: { x: 12, y: 24 } as google.maps.Point,
}

export const GREEN_PIN = {
  ...ORANGE_PIN,
  fillColor: '#22c55e',
  strokeColor: '#047857',
}

// export const BLUE_PIN = {
//   ...ORANGE_PIN,
//   fillColor: '#2563eb',
//   strokeColor: '#dbeafe',
// }

// export const GRAY_PIN = {
//   ...ORANGE_PIN,
//   fillColor: '#a8a29e',
//   strokeColor: '#57534e',
// }

export const THICK_ORANGE_PIN = {
  path: 'M14.3333 10.8908C12.6802 12.5437 11.3689 14.506 10.4743 16.6657C9.57961 18.8254 9.11914 21.1402 9.11914 23.4779C9.11914 25.8156 9.57961 28.1304 10.4743 30.2901C11.3689 32.4498 12.6802 34.4121 14.3332 36.065C14.3333 36.065 14.3333 36.0651 14.3333 36.0651L26.2132 47.945L26.9204 48.6521L27.6275 47.945L39.5075 36.065C42.8458 32.7267 44.7212 28.199 44.7212 23.4779C44.7212 18.7568 42.8458 14.2291 39.5075 10.8908C36.1692 7.55251 31.6414 5.67706 26.9204 5.67706C22.1993 5.67706 17.6716 7.55247 14.3333 10.8908ZM14.3333 10.8908L15.0404 11.5979L14.3333 10.8908ZM29.6074 26.1649C28.8947 26.8776 27.9282 27.2779 26.9204 27.2779C25.9125 27.2779 24.946 26.8776 24.2333 26.1649C23.5207 25.4523 23.1204 24.4857 23.1204 23.4779C23.1204 22.4701 23.5207 21.5036 24.2333 20.7909C24.946 20.0783 25.9125 19.6779 26.9204 19.6779C27.9282 19.6779 28.8947 20.0783 29.6074 20.7909C30.32 21.5036 30.7204 22.4701 30.7204 23.4779C30.7204 24.4857 30.32 25.4523 29.6074 26.1649Z',
  fillColor: '#F97316',
  fillOpacity: 1,
  scale: 0.9,
  strokeColor: '#ffffff',
  strokeWeight: 2,
  anchor: { x: 25, y: 40 } as google.maps.Point,
}

export const THICK_DARK_ORANGE_PIN = {
  ...THICK_ORANGE_PIN,
  fillColor: '#C2410C',
  strokeColor: '#ffffff',
}
