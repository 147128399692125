import { useRef } from 'react'
import useUppy from '../hooks/useUppy'

const UploadButton = ({
  allowedFileTypes,
  onUploadComplete,
  onError,
  children,
  onUploadStarted,
}: {
  allowedFileTypes: string[]
  onUploadComplete: (files: { name: string; url: string }[]) => Promise<unknown>
  onError: (error: string) => void
  children: React.ReactNode
  onUploadStarted?: () => void
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const uppy = useUppy({
    allowedFileTypes,
    autoProceed: true,

    onComplete: (newFiles) => onUploadComplete(newFiles.map((f) => ({ url: f.url, name: f.name }))),
  })

  const onFilesChange = (files: (File | null)[]) => {
    if (onUploadStarted) {
      onUploadStarted()
    }

    files.forEach((file) => {
      if (file) {
        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          onError(err.isRestriction ? `${err}` : 'Error uploading file')
        }
      }
    })
  }

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onClick={() => fileInputRef.current?.click()}
        onDragLeave={(e) => {
          e.preventDefault()
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDrop={(event) => {
          event.preventDefault()
          const files = event.dataTransfer.items
            ? Array.from(event.dataTransfer.items)
                .filter((item) => item.kind === 'file')
                .map((item) => item.getAsFile())
            : Array.from(event.dataTransfer.files)
          onFilesChange(files)
        }}
      >
        {children}
      </span>

      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept={allowedFileTypes?.join(',')}
        multiple
        onChange={(event) => onFilesChange(Array.from(event.target.files || []))}
        value=""
      />
    </>
  )
}

export default UploadButton
