import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Dropdown from '@/gf/components/next/Dropdown'
import { Maybe } from '@/types'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { useMemo } from 'react'

interface Step<T> {
  label: string
  value: T | null
}

const FilterDropdown = <T extends string>({
  title,
  value,
  onChange,
  steps,
}: {
  title: string
  value: Maybe<T>
  onChange: (newValue: Maybe<T>) => void
  steps: Step<T>[]
}) => {
  const currentStep = useMemo(
    () => steps.find((s) => s.value !== null && s.value === value) ?? null,
    [value]
  )

  return (
    <Dropdown
      trigger={
        currentStep ? (
          <Action.P className="flex items-center" size="sm">
            {currentStep.label}
            <ChevronDownIcon className="ml-0.5 -mr-1 w-5 h-4 inline-block" />
          </Action.P>
        ) : (
          <Action.S className="flex items-center" size="sm">
            {title}
            <ChevronDownIcon className="ml-0.5 -mr-1 w-5 h-4 inline-block" />
          </Action.S>
        )
      }
      placement="bottom-start"
      closeOnMenuClick
    >
      <Box className="mt-2 overflow-hidden min-w-40">
        <div className="px-1.5 py-0.5 bg-gray-100 text-gray-500 text-xs font-medium border-b border-gray-200">
          {title}
        </div>
        <ul>
          {steps.map((step) => (
            <li key={step.value} className="block">
              <button
                type="button"
                onClick={() => onChange(step.value ? step.value : null)}
                className="px-3 py-2 group hover:bg-orange-500 hover:text-white inline-flex justify-between w-full text-sm"
              >
                {step.label}
                {(currentStep?.value ? currentStep.value : null) === step.value && (
                  <CheckIcon className="w-5 h-5 flex shrink-0 text-gearflow group-hover:text-white" />
                )}
              </button>
            </li>
          ))}
        </ul>
      </Box>
    </Dropdown>
  )
}

export default FilterDropdown
