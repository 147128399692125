import debounce from 'lodash/debounce'
import {
  AddressSuggestion,
  AutocompleteSuggestion,
  InternationalAddressSuggestion,
} from '../../types'

const embeddedKey = process.env.SMARTY_EMBEDDED_KEY

// Returns the display string for an address autocomplete suggestion
const addressSuggestionDisplayString = (suggestion: AddressSuggestion) => {
  let entries = ''
  if (suggestion.entries > 1) {
    entries = ` (${suggestion.entries} entries)`
  }
  return `${suggestion.lineOne + entries} ${suggestion.city}, ${suggestion.state} ${
    suggestion.postalCode
  }`
}

const internationalAddressSuggestionDisplayString = (suggestion: InternationalAddressSuggestion) =>
  suggestion.entries > 1
    ? `${suggestion.addressText} (${suggestion.entries} entries)`
    : suggestion.addressText

// Returns the list of suggestions for an address autocomplete search
// If there is an error, an empty array is returned
// Usually, we want to use this function with debouncing; for that, use debouncedAutocompleteSearch (defined below)
const autocompleteSearch = async (
  query: string,
  international = false,
  selected = '',
  addressId = ''
): Promise<AutocompleteSuggestion[]> => {
  if (!embeddedKey || query === '') return []

  const host = international
    ? 'https://international-autocomplete.api.smarty.com/v2'
    : 'https://us-autocomplete-pro.api.smarty.com'
  const addressIdPath = addressId ? `/${addressId}` : ''
  const path = `/lookup${addressIdPath}`
  const params: {
    key: string
    search: string
    country?: string
    selected?: string
    addressId?: string
  } = {
    key: embeddedKey,
    search: encodeURIComponent(query),
  }
  if (international) params.country = 'CAN'
  if (selected) params.selected = encodeURIComponent(selected)
  const url = `${host}${path}?${Object.entries(params)
    .map((param) => param.join('='))
    .join('&')}`

  return fetch(url)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw new Error('Something went wrong on api server!')
    })
    .then((response) => {
      if (!international && response.suggestions) {
        return response.suggestions
          .map((suggestion) => ({
            lineOne: `${suggestion.street_line}${
              suggestion.secondary ? ` ${suggestion.secondary}` : ''
            }`,
            lineTwo: '',
            city: suggestion.city,
            state: suggestion.state,
            postalCode: suggestion.zipcode,
            entries: suggestion.entries,
          }))
          .map((suggestion) => ({
            suggestion,
            display: addressSuggestionDisplayString(suggestion),
          }))
      }
      if (international && response.candidates) {
        return response.candidates.map((candidate) => {
          if (candidate.street) {
            const suggestion = {
              lineOne: candidate.street,
              lineTwo: '',
              city: candidate.locality,
              state: candidate.administrative_area,
              postalCode: candidate.postal_code,
            }
            return {
              suggestion,
              display: addressSuggestionDisplayString(suggestion as AddressSuggestion),
            }
          }
          const suggestion = {
            addressId: candidate.address_id,
            addressText: candidate.address_text,
            entries: candidate.entries,
          }
          return { suggestion, display: internationalAddressSuggestionDisplayString(suggestion) }
        })
      }
      return []
    })
    .catch((error) => {
      console.log(error)
      return []
    })
}

// Debounced function to search address autocomplete suggestions and set them in the component state
// Use this function as a ref in your component; for examlpe,
// const debouncedAutocompleteSearch = useRef(
//   Smarty.debouncedAutocompleteSearch((suggestions) => setAutocompleteSuggestions(suggestions))
// ).current
const debouncedAutocompleteSearch = (
  handleAutocompleteSuggestions: (suggestions: AutocompleteSuggestion[]) => void,
  wait = 250
) =>
  debounce(
    (query, international = false, selected = '') =>
      autocompleteSearch(query, international, selected).then(handleAutocompleteSuggestions),
    wait
  )

export default { autocompleteSearch, debouncedAutocompleteSearch }
