import {
  Organization as OrganizationT,
  ReadReceipt as ReadReceiptT,
  User as UserT,
} from '@/dealers/_gen/gql'
import classNames from 'classnames'
import { nth, uniqBy } from 'lodash'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import MessageAttachment from '../../../gf/components/MessageAttachment'
import Spinner from '../../../gf/components/Spinner'
import AttachmentM from '../../../gf/modules/Attachment'
import ConversationM from '../../../gf/modules/Conversation'
import Time from '../../../gf/modules/Time'
import UserM from '../../../gf/modules/User'
import { useReadReceiptsForConversationT } from '../Inbox'
import ConversationRowIcon from './ConversationRowIcon'
import { Conversation, UseMessagesForConversationHook } from './types'

type ReadReceipt = Pick<ReadReceiptT, 'id' | 'updatedAt'> & {
  user: Pick<UserT, 'id' | 'role'> & {
    organization: Pick<OrganizationT, 'id'> | null
    name: string | null
  }
}

const InboxMessages = ({
  conversation,
  useMessagesForConversation,
  useReadReceiptsForConversation,
  refetchConversations,
  userId,
  userOrganizationId,
  displayCustomerContactInfo,
}: {
  conversation: Conversation
  useMessagesForConversation: UseMessagesForConversationHook
  useReadReceiptsForConversation: useReadReceiptsForConversationT
  refetchConversations: () => Promise<unknown>
  userId: string
  userOrganizationId?: string
  displayCustomerContactInfo: boolean
}) => {
  const { data: messages, error } = useMessagesForConversation({
    conversationId: conversation.id,
    // Only refetch if the conversation is unread, so we can fetch the correct "read" status
    refetchOnCompleted: conversation.unreadMessages ? refetchConversations : undefined,
  })
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const { data: readReceiptsData } = useReadReceiptsForConversation({
    variables: { conversationId: conversation.id },
    pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
  })

  // Scroll to the bottom of the messages when the messages load and there's a new message
  // TODO: figure out a better way to do this
  useEffect(() => {
    if (messages) messagesEndRef.current?.scrollIntoView()
  }, [messagesEndRef, messages, readReceiptsData])

  const getDisplayName = (
    user: { name: string | null } & { organization: Pick<OrganizationT, 'id'> | null }
  ) =>
    displayCustomerContactInfo ||
    conversation.source.user.organization?.id !== user.organization?.id
      ? user.name
      : ConversationM.getHiddenCustomerName(user.name)

  const readReceiptsDisplay = useMemo(() => {
    if (!messages || !readReceiptsData) return undefined
    const lastMessageUserId = nth(messages, 0)?.user.id
    // Get users who have sent a message in the conversation thread
    const activeUsers = uniqBy(
      messages.map((message) => message.user),
      ({ id }) => id
    )
    const { active: activeReadReceipts, inactive: inactiveReadReceipts } =
      readReceiptsData.readReceiptsForConversation.reduce(
        (
          {
            active: activeAcc,
            inactive: inactiveAcc,
          }: { active: ReadReceipt[]; inactive: ReadReceipt[] },
          readReceipt
        ) =>
          // Filter out the current user and the users not in the conversation thread
          readReceipt.user.id !== userId && activeUsers.some(({ id }) => id === readReceipt.user.id)
            ? { active: [...activeAcc, readReceipt], inactive: inactiveAcc }
            : { active: activeAcc, inactive: [...inactiveAcc, readReceipt] },
        { active: [], inactive: [] }
      )
    // Add read receipts for users who haven't sent a message. Limit to one read receipt, and only add if user is from
    // the opposite party in the conversation
    const inactiveReadReceipt =
      activeReadReceipts.length === 0
        ? inactiveReadReceipts.find(
            (readReceipt) =>
              readReceipt.user.id !== userId &&
              readReceipt.user.id !== lastMessageUserId &&
              readReceipt.user.organization?.id !== userOrganizationId &&
              (!UserM.isAdmin(readReceipt.user) || conversation.admin)
          )
        : undefined
    return [
      ...activeReadReceipts.map(({ updatedAt, user }) => ({
        updatedAt,
        name: getDisplayName(user),
        userId: user.id,
      })),
      ...(inactiveReadReceipt
        ? [
            {
              updatedAt: inactiveReadReceipt.updatedAt,
              name: '',
              userId: inactiveReadReceipt.user.id,
            },
          ]
        : []),
      // Don't show read receipt for the sender if it's the most recent message
    ].filter((readReceipt) => readReceipt.userId !== lastMessageUserId)
  }, [readReceiptsData?.readReceiptsForConversation, messages])

  return (
    <div className="pt-4 pb-1 px-4 flex flex-col grow overflow-y-scroll">
      {error && !messages ? (
        <div className="flex grow text-sm text-gray-500 justify-center items-center">
          {error.message}
        </div>
      ) : !messages ? (
        <div className="flex grow justify-center items-center">
          <Spinner />
        </div>
      ) : messages.length === 0 ? (
        <div className="flex grow text-sm text-gray-500 justify-center items-center">
          Send a message
        </div>
      ) : (
        <>
          <div className="flex justify-center mx-1 mb-2 text-xs text-gray-500">
            Beginning of conversation
          </div>
          {[...messages].reverse().map((message, index, array) => {
            const messageFromUser = message.user?.id === userId
            const messageUserName = getDisplayName(message.user)
            const nextMessage = index !== array.length - 1 ? array[index + 1] : undefined
            const messageReadReceipts = uniqBy(
              readReceiptsDisplay
                ?.filter(
                  (readReceipt) =>
                    message.insertedAt < readReceipt.updatedAt &&
                    (!nextMessage || readReceipt.updatedAt < nextMessage.insertedAt)
                )
                .sort((a, b) => (a.updatedAt < b.updatedAt ? -1 : 1)),
              ({ name }) => name
            )
            return (
              <Fragment key={`message-${message.id}`}>
                <div
                  className={classNames('my-2 flex flex-row justify-start space-x-1', {
                    'self-start items-start': !messageFromUser,
                    'self-end items-end': messageFromUser,
                  })}
                >
                  {/* User icon */}
                  {!messageFromUser && (
                    <ConversationRowIcon.User
                      type={
                        UserM.isAdmin(message.user)
                          ? 'admin'
                          : message.user.organization?.id &&
                              message.user.organization.id ===
                                conversation.source.user.organization?.id
                            ? 'buyer'
                            : 'supplier'
                      }
                      name={messageUserName}
                    />
                  )}

                  <div
                    className={classNames('flex flex-col space-y-0.5', {
                      'self-start items-start': !messageFromUser,
                      'self-end items-end': messageFromUser,
                    })}
                  >
                    {/* Attachments */}
                    {message.attachmentUrls.map((attachmentUrl) => (
                      <a
                        key={`${message.id}-${attachmentUrl}`}
                        className={classNames(
                          'inline-flex',
                          messageFromUser ? 'self-end items-end' : 'self-start items-start'
                        )}
                        href={attachmentUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {attachmentUrl.includes('.pdf') ? (
                          <MessageAttachment
                            className="max-w-44 xs:max-w-56 sm:max-w-72"
                            name={AttachmentM.nameFromUrl(attachmentUrl)}
                            blue={messageFromUser}
                          />
                        ) : attachmentUrl.includes('.mov') || attachmentUrl.includes('.mp4') ? (
                          // eslint-disable-next-line jsx-a11y/media-has-caption
                          <video controls>
                            <source src={attachmentUrl} />
                          </video>
                        ) : (
                          <img
                            className="rounded-lg max-w-44 xs:max-w-56 sm:max-w-72 max-h-52"
                            src={attachmentUrl}
                            alt={attachmentUrl}
                          />
                        )}
                      </a>
                    ))}

                    {message.text !== '' && (
                      <div
                        className={classNames(
                          messageFromUser
                            ? 'bg-blue-500 text-gray-50 ml-12 mr-0'
                            : 'bg-gray-200 text-gray-900 ml-0 mr-12',
                          'py-2 px-3 inline-flex text-sm rounded-lg whitespace-pre-wrap'
                        )}
                      >
                        {message.text}
                      </div>
                    )}
                    <div className="mx-1 text-xs text-gray-500">
                      {Time.formatDateTimeSmartYear(message.insertedAt)}
                    </div>
                    {message.user && (
                      <div className="mx-1 text-xs text-gray-500">
                        {messageUserName}
                        {UserM.isAdmin(message.user) ? ' (Gearflow Admin)' : ''}
                      </div>
                    )}
                  </div>
                </div>
                {messageReadReceipts && messageReadReceipts.length > 0 && (
                  <div
                    key={`${message.id}-read-receipts`}
                    className="w-full flex flex-col gap-y-1 justify-center items-center"
                  >
                    {messageReadReceipts.map((readReceipt) => (
                      <div
                        key={`read-receipt-${readReceipt.userId}`}
                        className="max-w-sm flex flex-row justify-center items-center gap-x-1 text-xs text-gray-500"
                      >
                        <div className="w-8 border-b border-gray-300" />
                        <div className="text-center">
                          Seen {readReceipt.name ? `by ${readReceipt.name}` : ''}{' '}
                          {Time.formatDateTimeSmartYear(readReceipt.updatedAt)}
                        </div>
                        <div className="w-8 border-b border-gray-300" />
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            )
          })}
          <div ref={messagesEndRef} key="messages-end" aria-hidden="true" />
        </>
      )}
    </div>
  )
}

export default InboxMessages
